import React, { useState } from 'react';
import { FullscreenImageView } from '../../components/FullscreenImageView';
import { Icon } from '../../components/Icons';
import { getActiveLanguage } from '../../Translate';
import styles from './ImageCarrousel.module.scss';

interface ImageCarrouselProps {
  images: { url: string, fullSizeUrl?: string, legend?: { [key: string]: string } }[];
}

export default function ImageCarrousel(props: ImageCarrouselProps) {
  const { images } = props;

  const [current, setCurrent] = useState(0);
  const [fullscreenOpen, setFullscreenOpen] = useState(false);

  const handleClickedNext = () => setCurrent((current + 1) % images.length);
  const handleClickedPrev = () => setCurrent((current + images.length - 1) % images.length);
  const handleClickedFullscreenClose = () => setFullscreenOpen(false);

  const currentUrl = images[current].url;
  const fullSizeUrl = images[current].fullSizeUrl || currentUrl;
  const legenda = (images[current]?.legend || {})[getActiveLanguage()] || '';
  const nextUrl = images.length > 1 ? images[(current + 1) % images.length]?.url : undefined;
  const prevUrl = images.length > 1 ? images[(current + images.length - 1) % images.length]?.url : undefined;

  const handleClicked = () => setFullscreenOpen(true);

  return <>
    <div className={styles.carrouselWrapper}>
      <div className={styles.carrousel}>
        <ImageCarrouselContent
          url={currentUrl}
          legenda={legenda}
          prevUrl={prevUrl}
          nextUrl={nextUrl}
          onClickedNext={handleClickedNext}
          onClickedPrev={handleClickedPrev}
          onClick={handleClicked}
        />
        <ImageCarrouselIndicator
          images={images}
          current={current}
        />
      </div>
      <div style={{ flexGrow: 1 }} />
    </div>
    {fullscreenOpen && fullSizeUrl && (
      <FullscreenImageView src={fullSizeUrl} caption={legenda} onClick={handleClickedFullscreenClose} />
    )}
  </>;
}

interface ImageCarrouselContentProps {
  url: string;
  legenda: string;
  prevUrl?: string;
  nextUrl?: string;
  onClickedNext: () => void;
  onClickedPrev: () => void;
  onClick: () => void;
}

function ImageCarrouselContent(props: ImageCarrouselContentProps) {
  const { url, legenda, prevUrl, nextUrl, onClickedNext, onClickedPrev, onClick } = props;

  return (
    <div className={styles.content}>
      <div className={styles.previousButton} onClick={onClickedPrev}>
        <i className={Icon.ChevronLeftLight} style={{ visibility: prevUrl ? 'visible' : 'hidden' }} />
      </div>
      <div className={styles.images}>
        <img className={styles.current} src={url} alt={legenda} onClick={onClick} />
      </div>
      <div className={styles.nextButton} onClick={onClickedNext}>
        <i className={Icon.ChevronRightLight} style={{ visibility: nextUrl ? 'visible' : 'hidden' }} />
      </div>
    </div>
  );
}

interface ImageCarrouselIndicatorProps {
  images: { url: string }[];
  current: number;
}

function ImageCarrouselIndicator(props: ImageCarrouselIndicatorProps) {
  const { images, current } = props;
  return (
    <div className={styles.indicator}>
      {images.map((_, index) => <div className={index === current ? styles.current : ''} />)}
    </div>
  );
}
