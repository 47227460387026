import React, { useMemo } from 'react';
import { Icon } from '../../components/Icons';
import { usePerson, usePublishedPersonStory } from '../../FunctionalAPI';
import { PersonQueryOptions } from '../../models/Person';
import { T } from '../../Translate';
import { PageID } from '../../utils/PageLinking';
import { PageProps, usePageHeader } from '../Page';
import styles from './index.module.scss';
import StoryView from './StoryView';
import { Col, Row } from "react-bootstrap";

interface ViewStoryPairPageProps extends PageProps {
  story1: string;
  story2: string;
  child: boolean;
}

const PersonWithEvents: PersonQueryOptions[] = [PersonQueryOptions.EventsWithPlaces];
export const ViewStoryPairPage = (props: ViewStoryPairPageProps) => {
  const { story1: story1id, story2: story2id, loading, child } = props;

  const story1 = usePublishedPersonStory(loading, story1id);
  const story2 = usePublishedPersonStory(loading, story2id);
  const person1 = usePerson(loading, story1 && story1.person_id_ref, PersonWithEvents, 'GUEST');
  const person2 = usePerson(loading, story2 && story2.person_id_ref, PersonWithEvents, 'GUEST');

  const subtitles = useMemo(() => [T('storyPair.subtitle')], []);
  usePageHeader(
    PageID.PublicViewPersonStory,
    `story1=${story1id}&story2=${story2id}${child ? '&child' : ''}`,
    Icon.Search,
    T('storyPair.title'),
    T('storyPair.title'),
    undefined,
    undefined,
    undefined,
    subtitles
  );

  return <>
    <div className={styles.header}>
      <div className='container'>
        <div className='container'>
            <Row>
              <Col md={12}>
                {T('storyPair.text').split('\n').map(paragraph => <p>{paragraph}</p>)}
              </Col>
            </Row>
          </div>
        </div>
    </div>
    <div className={styles.story}>
      <div className='container'>
        <Row>
          <Col lg={6}>
            {story1 && person1 && (
              <StoryView story={story1} person={person1} child={child} />
            )}
          </Col>
          <Col lg={6}>
            {story2 && person2 && (
              <StoryView story={story2} person={person2} child={child} />
            )}
          </Col>
        </Row>
      </div>
    </div>
  </>;
}
