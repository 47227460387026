import React, { useMemo, useState, useEffect } from 'react';
import { IProject, IProjectFields } from '../../models/Project';
import { Icon } from '../../components/Icons';
import { T, plural } from '../../Translate';
import { StringFieldTableColumn, ActionsTableColumn, TableRowActionStyle, ITableColumn } from '../../components/table/Fields';
import { IQueryResult } from '../../models/Generic';
import api from '../../api';
import { ConfirmationModal } from '../../modals/Confirmation';
import EditProjectModal from './EditProjectModal';
import { ListState, ListPage } from '../ListPage';
import { PageProps } from '../Page';
import { usePageState } from '../../redux/Actions';
import { useRefresh } from '../../utils/Functional';
import { PageID } from '../../utils/PageLinking';

export type ListProjectsStateProps = ListState;

const rowKey = (item: IProject) => item._id;
const PageSize = 10;

export default function ListProjects(props: PageProps) {
  const { history, loading, modals, via } = props;

  const [pageState, updatePageState] = usePageState('projects');
  const { filter, tablePage, sortColumn, sortDirection } = pageState;

  const [items, setItems] = useState<IQueryResult<IProject>>();
  const refresh = useRefresh(() => {
    loading.loading(api.findProjects(
      filter,
      tablePage * PageSize,
      PageSize,
      [[sortColumn, sortDirection]]
    ), plural('project'))
      .then(setItems);
  });
  useEffect(refresh, [filter, tablePage, sortColumn, sortDirection]);

  const handleClickedAdd = () => {
    modals.show<IProjectFields>(props => <EditProjectModal {...props} />)
      .then(data => api.createProject(data))
      .then(refresh);
  }

  const handleClickedRow = (project: IProject) => {
    history.push('/projects/' + project._id);
  }

  const columns: ITableColumn<IProject>[] = useMemo(
    () => {
      const handleClickedEdit = (project: IProject) => {
        modals.show<IProjectFields>(props => (
          <EditProjectModal project={project} {...props} />
        )).then(data => {
          const request = Object.assign(data, { _id: project._id });
          api.updateProject(request).then(refresh);
        });
      }
    
      const handleClickedRemove = async (project: IProject) => {
        const confirmed = await modals.show<boolean>(props => (
          <ConfirmationModal
            title={T('modal.removeProject.title')}
            message={T('modal.removeProject.message', { title: project.title })}
            warning={T('generic.cannotUndo')}
            rejectText={T('generic.action.cancel')}
            acceptText={T('generic.action.remove')}
            acceptStyle='danger'
            {...props}
          />
        ));
        if (!confirmed)
          return;
  
        await api.removeProject(project._id);
        refresh();
      }

      return [
        new StringFieldTableColumn('title', T('project.title')),
        new ActionsTableColumn('actions', T('project.actions'), project => [
          { icon: Icon.Edit, onClick: handleClickedEdit },
          { icon: Icon.TimesCircle, onClick: handleClickedRemove, style: TableRowActionStyle.Danger }
        ])
      ];
    },
    [modals, refresh]
  );

  return (
    <ListPage
      id={PageID.ListProjects}
      icon={Icon.Books}
      title={T('page.projects.title')}
      via={via}
      noun='project'
      columns={columns}
      items={items}
      state={pageState}
      updateState={updatePageState}
      rowKey={rowKey}
      onClickedRow={handleClickedRow}
      onClickedAdd={handleClickedAdd}
    />
  );
}
