import React from 'react';
import iffLogo from '../../../assets/images/logo-inflandersfields.png';
import ypresLogo from '../../../assets/images/vredesstad-ieper.png';
import { Col, Row } from 'react-bootstrap';
import styles from './PublicHeader.module.scss';
import { getActiveLanguage, getUILanguages, Language, T } from '../../../Translate';
import { ButtonLink } from '../../ButtonLink';
import { classes } from '../../../utils/Styles';
import { PageInfo } from '../../../pages/Page';
import { Link } from 'react-router-dom';

interface PublicHeaderProps {
  page: PageInfo;
  person?: boolean;
  ypresPeaceLogo?: boolean;
  onLanguageChanged: (language: Language) => void;
}

export const PublicHeader = (props: PublicHeaderProps) => {
  const { page, person, ypresPeaceLogo, onLanguageChanged } = props;
  const { title, subtitles, icon } = page;
  const back = page.breadcrumbs.length > 1 ? page.breadcrumbs[page.breadcrumbs.length - 2] : undefined;

  return (
    <section className={classes(styles.pageHeader, person ? styles.person : undefined)}>
      <Row className='flex-column-reverse flex-md-row'>
        <Col md={7}>
          {back && (
            <div className={styles.back}>
              <Link to={back.to}>
                &lsaquo; {T('publicSearch.backTo')} {back.title}
              </Link>
            </div>
          )}
          
          <div className='section-header clearfix'>
            <h2>{title}</h2>
            <h3>
              {icon && <i className={icon} style={{ paddingRight: '1rem' }} />}
              {(subtitles || ['']).map((subtitle, i) => (
                <>
                  {i > 0 && <br />}
                  {subtitle}
                </>
              ))}
            </h3>
          </div>
        </Col>

        <Col md={5}>
          <div className={styles.logo}>
            <div className={styles.languageSelector}>
              {getUILanguages().map((langcode, i) => (
                <span key={langcode}>
              {i > 0 && <span>&nbsp;|&nbsp;</span>}
                  <ButtonLink className={getActiveLanguage() === langcode ? styles.active : ''}
                              onClick={() => onLanguageChanged(langcode)}>
                {langcode.toUpperCase()}
              </ButtonLink>
            </span>
              ))}
            </div>
            <div style={{textAlign: 'right'}}>
              {ypresPeaceLogo && <img alt='Vredesstad Ieper' src={ypresLogo} />}
              <a href="http://inflandersfields.be"><img alt='logo' src={iffLogo}/></a>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  )
}
