import { useEffect, useMemo, useState } from "react";
import { Icon } from "../../components/Icons";
import { IProject } from "../../models/Project";
import { T } from "../../Translate";
import { PageID } from "../../utils/PageLinking";
import { PageProps, usePageHeader } from "../Page";
import { SpecialFunctionResult } from "../../models/SpecialFunction";
import api from "../../api";
import { usePageState } from "../../redux/Actions";
import { ListState } from "../ListPage";
import PublicSearchPersonsTable from "../PublicSearch/PublicSearchPersonsTable";
import { Container } from "react-bootstrap";
import { LoadingView } from "../../components/LoadingView";

interface PublicViewProjectProps extends PageProps {
  projectId: string;
}

export interface PublicViewProjectState extends ListState {
  results?: SpecialFunctionResult;
}

export default function PublicViewProject(props: PublicViewProjectProps) {
  const {projectId, history} = props;

  const [project, setProject] = useState<IProject>();
  const [pageState, updatePageState] = usePageState('publicViewProject');
  const {results} = pageState;

  useEffect(() => {
    api.getProject(projectId).then(setProject);
    api.callFunction('FindPersonAdvancedPublic', {project: projectId, dataset: 'any'}).then(results => updatePageState({results}));
  }, [projectId, updatePageState]);

  const subtitles = useMemo(() => [project?.title || '...'], [project]);
  usePageHeader(PageID.PublicViewProject, projectId, Icon.ListUl, T('menu.namelist'), project?.title || '...', undefined, undefined, undefined, subtitles);

  return results ? (
    <Container>
      <PublicSearchPersonsTable listState={pageState} updateListState={updatePageState} results={results} history={history} />
    </Container>
  ) : <LoadingView />;
}