import { useMemo } from "react";
import { Container } from "react-bootstrap";
import { usePerson } from "../../FunctionalAPI";
import { PersonQueryOptions } from "../../models/Person";
import { personEventSorter } from "../../models/PersonEvent";
import { getPageVia, PageID } from "../../utils/PageLinking";
import { PageProps, usePageHeader } from "../Page";
import { EventView } from "../ViewPerson/EventView";
import { Header } from "./Header";
import publicStyles from '../PublicSearch/index.module.scss';

interface ViewPersonPublicPageProps extends PageProps {
  id: string;
}

export const ViewPersonPublicPage = (props: ViewPersonPublicPageProps) => {
  const { id, loading, modals, via } = props;
  const person = usePerson(loading, id, [PersonQueryOptions.EventsWithPlaces, PersonQueryOptions.Documents, PersonQueryOptions.RelationPersons]);
  console.log(person);
  
  const subtitles = useMemo(() => [person?.lastName || ''], [person]);
  usePageHeader(
    PageID.ViewPerson,
    undefined,
    undefined,
    person?.firstName || '',
    person?.firstName || '',
    via || getPageVia(PageID.PublicSearch),
    person ? person.name : '...',
    undefined,
    subtitles);

  const eventViews = useMemo(() => {
    if (!person)
      return <div />;

    person.events.sort(personEventSorter);
    return person.events.map(event => <EventView key={event._id} event={event} />);
  }, [person]);

  return (
    <div className={publicStyles.content}>
      {person && <Header person={person} modals={modals} />}
      <Container>
        <div className='events-wrapper'>
          {eventViews}
        </div>
      </Container>
    </div>
  );
}
