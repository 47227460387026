import { singular, T } from "../Translate";
import { Icon } from "./Icons";

export function LoadingView() {
  return <div style={{ minHeight: 500, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    <span style={{textAlign: 'center'}}>
      {T('pending.loading', {x: singular('project')})}
      <br />
      <i className={Icon.Loading} />
    </span>
  </div>;
}
