import { Icon, LoadingIcon } from "../../components/Icons";
import { TopTab, TopTabs } from "../../components/TopTabs";
import { T, TranslationKey } from "../../Translate";
import { PageID } from "../../utils/PageLinking";
import { PageProps, usePageHeader } from "../Page";
import { History } from 'history';
import api from '../../api';
import { useEffect, useMemo, useState } from "react";
import { ISpecialFunction, SpecialFunctionArguments, SpecialFunctionResult, ValidationErrors } from "../../models/SpecialFunction";
import { SpecialFunctionForm } from "../SpecialFunctions/SpecialFunctionForm";
import { Button, Col, Row } from "react-bootstrap";
import { getEventIcon, getMajorPriorityForEventType, PersonEventType } from "../../models/PersonEvent";
import styles from './index.module.scss';
import { classes } from "../../utils/Styles";
import PublicSearchPersonsTable from "./PublicSearchPersonsTable";
import { ListState } from "../ListPage";
import { usePageState } from "../../redux/Actions";

interface SearchTabProps {
  func?: ISpecialFunction;
  errors: ValidationErrors;
  args: SpecialFunctionArguments;
  setArgs: (args: SpecialFunctionArguments) => void;
  execute: (func: ISpecialFunction, args: SpecialFunctionArguments) => void;
}

export interface PublicSearchState extends ListState {
  basicInput: SpecialFunctionArguments;
  advancedInput: SpecialFunctionArguments;
  selectedTab: string;
  results?: SpecialFunctionResult;
}

function SearchTab(props: SearchTabProps) {
  const { func, errors, args, setArgs, execute } = props;

  const handleClickedReset = () => {
    setArgs({});
  };

  if (func) {
    return <>
      <div className={styles.actions}>
        <Button onClick={handleClickedReset}>
          {T('page.specialFunctions.reset')}
        </Button>
        <Button onClick={() => func && execute(func, args)}>
          {T('page.specialFunctions.execute')}
        </Button>
      </div>
      <h3><i className={Icon.Search} /> {T('publicSearch.fields')}</h3>
      <SpecialFunctionForm
        args={args}
        func={func}
        errors={errors}
        onArgumentsChange={setArgs}
        renderRepeatable={(field, onClickedAdd) => {
          const sorted = field.types;
          sorted.sort((a, b) => getMajorPriorityForEventType(a.name as PersonEventType) - getMajorPriorityForEventType(b.name as PersonEventType))
          return <>
            <p>{T('publicSearch.addFilter.info')}</p>
            <div>
              {field.types.map(type => (
                <Row className={styles.advancedSearchRow} onClick={() => onClickedAdd(type.name)}>
                  <Col sm={1}>
                    <span className={styles.filtericon}>
                      <i className={Icon.Filter} />
                    </span>
                  </Col>
                  <Col sm={4}>
                    <i className={getEventIcon(type.name as PersonEventType)} style={{ paddingRight: '0.5rem' }} />
                    {T('specialFunctions.repeatable.filters.typeHeader')}
                    {T(('specialFunctions.repeatable.filters.type.' + type.name) as TranslationKey)}
                  </Col>
                  <Col sm={7}>
                    {T(('publicSearch.advanced.filterInfo.' + type.name) as TranslationKey)}
                  </Col>
                </Row>
              ))}
            </div>
          </>;
        }}
      />
    </>;
  } else {
    return <p style={{ textAlign: 'center' }}><LoadingIcon /></p>;
  }
}

interface ResultsTabProps {
  state: PublicSearchState;
  updateState: (updates: Partial<PublicSearchState>) => void;
  history: History<any>;
}

function ResultsTab(props: ResultsTabProps) {
  const { state, updateState, history } = props;
  const { results } = state;

  return results && results.type === 'table' ? <>
    <h3><i className={Icon.ListUl} /> {T('publicSearch.results')} ({results.data.length}{results.data.length < results.total && '/' + results.total})</h3>
    <PublicSearchPersonsTable listState={state} updateListState={updateState} results={results} history={history} />
  </> : <p></p>;
}

export default function PublicSearch(props: PageProps) {
  const { loading, history } = props;
  
  const [pageState, updatePageState] = usePageState('publicSearch');
  const { selectedTab } = pageState;
  const [basicSearch, setBasicSearch] = useState<ISpecialFunction>();
  const [advancedSearch, setAdvancedSearch] = useState<ISpecialFunction>();

  const [errors, setErrors] = useState<ValidationErrors>({});

  useEffect(() => {
    api.getFunction('FindPerson').then(setBasicSearch);
    api.getFunction('FindPersonAdvancedPublic').then(setAdvancedSearch);
  }, []);

  const handleClickedExecute = (func: ISpecialFunction, args: SpecialFunctionArguments) => {
    api.validateFunction(func.name, args).then(result => {
      if (result.status !== 'OK') {
        setErrors(result.data || {});
        return;
      }

      loading.loading(api.callFunction(func.name, args), T('page.specialFunctions.results'))
        .then(results => {
          updatePageState({
            selectedTab: 'results',
            results
          });
        });
    });
  };

  const subtitles = useMemo(() => [T('publicSearch.title')], []);
  usePageHeader(
    PageID.PublicSearch,
    undefined,
    Icon.Search,
    T('menu.namelist'),
    T('publicSearch.title'),
    undefined,
    undefined,
    undefined,
    subtitles);
  
  const tabs: TopTab[] = [
    {
      icon: Icon.Search,
      title: T('publicSearch.basic'),
      id: 'basic',
      content: () => (
        <SearchTab
          func={basicSearch}
          execute={handleClickedExecute}
          errors={errors}
          args={pageState.basicInput}
          setArgs={args => updatePageState({ basicInput: args })}
        />
      )
    }, {
      icon: Icon.Search,
      title: T('publicSearch.advanced'),
      id: 'advanced',
      content: () => (
        <SearchTab
          func={advancedSearch}
          execute={handleClickedExecute}
          errors={errors}
          args={pageState.advancedInput}
          setArgs={args => updatePageState({ advancedInput: args })}
        />
      )
    }, {
      icon: Icon.Users,
      title: T('publicSearch.results'),
      id: 'results',
      content: () => <ResultsTab state={pageState} updateState={updatePageState} history={history} />
    }
  ];

  return (
    <div className={classes('container', styles.content)}>
      <TopTabs
        id='public-search-tabs'
        defaultActiveKey='basic'
        activeKey={selectedTab}
        onSelect={tab => updatePageState({ selectedTab: tab })}
        tabs={tabs}
      />
    </div>
  );
}
